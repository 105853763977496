

export const crmService = {
   get,
   update,
   create,
   getMaintenance,
   createGeneralMaintenance,
   createCountryMaintenance,
   updateCountryMaintenance,
   updateGeneralMaintenance
};


// get crm settings
function get() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_API_URL}/crm/get`, requestOptions).then(handleResponse);
}

// edit crm
function update(crm_general_id, object) {
   const requestOptions = {
      method: 'PUT',
      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/crm/update/${crm_general_id}`, requestOptions).then(handleResponse);
}

function create(object) {
   const requestOptions = {
      method: 'post',

      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/crm/create`, requestOptions).then(handleResponse);
}
function createGeneralMaintenance(object) {
   console.log(object)
   const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },

      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/website/createGeneralMaintenance`, requestOptions).then(handleResponse);
}
function createCountryMaintenance(object) {
   console.log(object)
   const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },

      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/website/createCountryMaintenance`, requestOptions).then(handleResponse);
}
function updateGeneralMaintenance(id, object) {
   const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },

      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/website/updateGeneralMaintenance/${id}`, requestOptions).then(handleResponse);
}
function updateCountryMaintenance(id, object) {
   console.log(id, object)
   const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },

      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/website/updateCountryMaintenance/${id}`, requestOptions).then(handleResponse);
}
// get maintenance 
function getMaintenance() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_API_URL}/website/getMaintenance`, requestOptions).then(handleResponse);
}




// handle response
function handleResponse(response) {
   return response.text().then(text => {
      if (response.ok) {
         const data = text && JSON.parse(text);
         return data;
      }

   }).catch(error => { console.log(error) })
      ;
}
