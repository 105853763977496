/**
 * Router service file
*/
import Dashboard from 'routes/Dashboard'

// Async component
import {
   AsyncUserSettingsComponent,
   AsyncCrmSettingsComponent,
   AsyncControlFundComponent,
   AsyncMaintenanceComponent
} from 'routes';
import Customers from 'routes/Customers';
import Products from 'routes/Products';
import Api from 'routes/Api';
import Invoices from 'routes/invoices';
import Users from 'routes/Users';
import vehicle from 'routes/vehicle';
export default [
   {
      path: 'dashboard',
      component: Dashboard
   },
   {
      path: 'customer',
      component: Customers
   },
   {
      path: 'users',
      component: Users
   },
   {
      path: 'user-settings',
      component: AsyncUserSettingsComponent
   },
   {
      path: 'settings',
      component: AsyncCrmSettingsComponent
   },
   {
      path: 'control',
      component: AsyncControlFundComponent
   },
   {
      path: 'maintenance',
      component: AsyncMaintenanceComponent
   },
   
   {
      path: 'products',
      component: Products
   },
   {
      path: 'vehicle',
      component: vehicle
   },
   {
      path: 'orders',
      component: Invoices
   },
   {
      path: 'api',
      component: Api
   }
]