/**
 * Vehicle Actions
 */

import {
   ADD_NEW_VEHICLE,
   DELETE_VEHICLE,
   UPDATE_VEHICLE
} from 'actions/Types';

export const addNewVehicle  = (data) => ({
   type: ADD_NEW_VEHICLE,
   payload: data
});

export const deleteVehicle  = (data) => ({
   type: DELETE_VEHICLE,
   payload: data
});

export const onUpdateVehicle = (data, ID) => ({
   type: UPDATE_VEHICLE,
   payload: { data, ID }
})