

export const controlFundService = {
   get,
   create,
   remove,
   update,
   controleFundEmail

};


function remove(id) {
   const requestOptions = {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/control-fund/delete/${id}`, requestOptions)
      .then(handleResponse)

}

function get() {
   const requestOptions = {
      method: 'GET'
   };
   return fetch(`${process.env.REACT_APP_API_URL}/control-fund/get`, requestOptions).then(handleResponse);
}

function create(object) {
   console.log(object, 'object')
   const requestOptions = {
      method: 'post',
      headers: {
         'Content-Type': 'application/json',
      },
      body: object
   };
   console.log(requestOptions, "requestOptions")
   return fetch(`${process.env.REACT_APP_API_URL}/control-fund/create`, requestOptions).then(handleResponse);
}
function controleFundEmail(object) {
   const requestOptions = {
      method: 'POST',
      headers: {
         'Content-type': 'application/json',
      },
      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/control-fund/email`, requestOptions).then(handleResponse);
}
function update(id, object) {
   const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: object
   };

   return fetch(`${process.env.REACT_APP_API_URL}/control-fund/update/${id}`, requestOptions)
      .then(handleResponse)

}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      if (response.ok) {
         const data = text && JSON.parse(text);
         return data;
      }

   }).catch(error => { console.log(error) })
      ;
}


