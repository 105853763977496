export const ApiService = {
    companySearch,
    getCompany,
    getContact,
    getNotices,
    getDocumentList,
    downloadDocument,
    getCompanyMasterSet,
    getCompanyOfficers,
    getCompanyInsolvenzcheck,
    protocols,
    getCompanywithSearchdetailed 
};

function companySearch(keyword) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(keyword)
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/companies`, requestOptions).then(handleResponse);

}

function getCompanyInsolvenzcheck(company_number) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/companies/insolvenzCheck/${company_number}`, requestOptions).then(handleResponse);
}
function getCompanyOfficers(company_number) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/officers/${company_number}`, requestOptions).then(handleResponse);
}
function getCompanyMasterSet(company_number) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/companies/fullDetails/${company_number}`, requestOptions).then(handleResponse);
}
function getDocumentList(company_number) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/document/${company_number}`, requestOptions).then(handleResponse);

}
function getNotices(company_number) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/notices/${company_number}`, requestOptions).then(handleResponse);

}
function getContact(company_number) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/companies/contact/${company_number}`, requestOptions).then(handleResponse);

}


function getCompany(object) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/companies/getCompany`, requestOptions).then(handleResponse);

}
function getCompanywithSearchdetailed (object){
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/companies/search/detailed`, requestOptions).then(handleResponse);

}
function handleResponse(response) {
    return response.text().then(text => {
        if (response.ok) {
            const data = text && JSON.parse(text);
            return data;
        }

    }).catch(error => { console.log(error) })
        ;
}

function downloadDocument(object, document_id) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/document/${document_id}/base64`, requestOptions).then(handleResponse);

}

function protocols() {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/SHApi/protocols`, requestOptions).then(handleResponse);

}