/**
 * Courses Routing File
 */

 import React from 'react'
 import { Route, Switch } from 'react-router-dom'
 import {
    VehicleListComponent
 
 
 } from 'routes';
 
 const vehicle = ({ match }) => (
     <Switch>
        <Route path={`${match.url}`} component={VehicleListComponent}></Route>
       
         </Switch>
 )
 export default vehicle;