export const vehicleService = {
   get,
   remove,
   create,
   update
};


function get() {
   const requestOptions={
       method:'GET'
   };
   return fetch(`${process.env.REACT_APP_API_URL}/vehicle`, requestOptions).then(handleResponse);
}


function remove(product_id) {
   const requestOptions = {
      method: 'delete',
   }

   return fetch(
      `${process.env.REACT_APP_API_URL}/vehicle/${product_id}`,
      requestOptions
   ).then(handleResponse)
}

function create(object) {
   const requestOptions = {
      method: 'POST',
      body: object

   };

   return fetch(`${process.env.REACT_APP_API_URL}/vehicle`, requestOptions).then(handleResponse);
}

function update(id, object) {
   const requestOptions = {
      method: 'PUT',
      body: object

   };

   return fetch(`${process.env.REACT_APP_API_URL}/vehicle/${id}`, requestOptions).then(handleResponse);
}

// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}