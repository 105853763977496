/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  CustomersComponent,
  CustomerDetailsComponent,
  UpdateCustomerComponent,
  ExportCustomerComponent
} from 'routes'

const Customers = ({ match }) => (

  < Switch >
    <Route
      path={`${match.url}/details`}
      component={CustomerDetailsComponent}
    ></Route>
    <Route
      path={`${match.url}/List`}
      component={CustomersComponent}
    ></Route>
    <Route
      path={`${match.url}/update`}
      component={UpdateCustomerComponent}
    ></Route>
    <Route
      path={`${match.url}/export`}
      component={ExportCustomerComponent}
    ></Route>

  </Switch >
)
export default Customers
