
export const customerService = {
    getAll,
    getById,
    update,
    remove,
    add
};
function getById(customer_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/customer/getCustomerById/${customer_id}`, requestOptions)
        .then(handleResponse)

}
function remove(customer_id) {
    const requestOptions = {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/customer/delete/${customer_id}`, requestOptions)
        .then(handleResponse)

}

function getAll() {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/customer/getAll`, requestOptions)
        .then(handleResponse)

}

function update(id, object) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(object)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/customer/update/${id}`, requestOptions)
        .then(handleResponse)

}

function add(object) {
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(object)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/customer/register`, requestOptions)
        .then(handleResponse)

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                localStorage.removeItem('toll_user');
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
