/**
 * vehicle Reducer
 */
//action types
import {
   DELETE_VEHICLE,
   ADD_NEW_VEHICLE,
   UPDATE_VEHICLE
} from 'actions/Types';


const INITIAL_STATE = {
   vehiclesData: []
}

export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
      // add product to cart 
      case ADD_NEW_VEHICLE:
         let vehicle = action.payload;
         let newvehicle = {
            title: vehicle.title,
            description: vehicle.description,
         }
         return {
            ...state,
            vehiclesData: [...state.vehiclesData, newvehicle]
         }

      case DELETE_VEHICLE:
         let removevehicle = action.payload;
         let newData = state.vehiclesData.filter((vehicleItem) => vehicleItem.id !== removevehicle.id)
         return {
            ...state,
            vehiclesData: newData
         }
      // update 
      case UPDATE_VEHICLE:
         let updatevehicle = action.payload;
         let newvehiclesData = [];
         for (const item of state.vehiclesData) {
            if (item.id === updatevehicle.ID) {
               item.name = updatevehicle.data.name;
               item.description = updatevehicle.data.description;
               item.LengthList = updatevehicle.data.LengthList;
            }
            newvehiclesData.push(item)
         }
         return {
            ...state,
            vehiclesData: newvehiclesData
         }
      // default case	
      default:
         return { ...state }
   }
}