/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  UpdateUserComponent,
  UsersComponent,
  UserDetailsComponent
} from 'routes'

const Users = ({ match }) => (

  < Switch >
    <Route
      path={`${match.url}/list`}
      component={UsersComponent}
    ></Route>
    <Route
      path={`${match.url}/update`}
      component={UpdateUserComponent}
    ></Route>

  </Switch >
)
export default Users
